export const EDIT_LISTING_MAPPING = {
  digimon: ['game', 'format', 'tournamentdetails'],
  'flesh-and-blood': ['game', 'format', 'tournamentdetails'],
  lorcana: ['game', 'format', 'tournamentdetails'],
  'magic-the-gathering': [
    'game',
    'format',
    'rules',
    'twoHeadedGiantMagicTheGathering',
    'tournamentdetails',
  ],
  'one-piece': ['game', 'format', 'buddyBattleOnePiece', 'tournamentdetails'],
  pokemon: ['game', 'format', 'tournamentdetails'],
  'yu-gi-oh': ['game', 'format', 'tierLevelYugioh', 'tournamentdetails'],
  dbscg: ['game', 'format', 'tierLevelYugioh', 'tournamentdetails'],
  'star-wars': ['game', 'format', 'tournamentdetails'],
};
