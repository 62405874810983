import React from 'react';
import { ListingCard, Modal, SearchModal } from '../../../components';
import css from './MapListingsModal.module.css';
import SearchMap from '../SearchMap/SearchMap';
import { isArrayLength } from '../../../util/genericHelpers';

function MapListingsModal(props) {
  const {
    isSearchMapFilterOpen,
    intl,
    openMapFilter,
    onMapMoveEnd,
    onManageDisableScrolling,
    activeListingId,
    bounds,
    origin,
    location,
    listings,
    renderSecondaryFilters,
    setActiveListing,
    isMobileLayout,
  } = props;

  if (!isSearchMapFilterOpen) {
    return <></>;
  }

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50; // eslint-disable-line no-unused-vars
      const panelLargeWidth = 62.5; // eslint-disable-line no-unused-vars
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };
  console.log(listings, 'listings');
  const modal = (
    <SearchModal
      className={css.mapPanel}
      id="SearchPage.map"
      isOpen={isSearchMapFilterOpen}
      onClose={() => openMapFilter(false)}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.mapListingsWrapper} data-testid="searchMapContainer">
        <div className={css.modalfilterSec}>{!isMobileLayout && renderSecondaryFilters}</div>
        <div className={css.listingResultSec}>
          {!isMobileLayout &&
            isArrayLength(listings) &&
            listings.map(l => (
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes(true)}
                setActiveListing={setActiveListing}
                isMapListingModalCard={true}
              />
            ))}
        </div>
        <SearchMap
          reusableContainerClassName={css.map}
          activeListingId={activeListingId}
          bounds={bounds}
          center={origin}
          isSearchMapOpenOnMobile={isSearchMapFilterOpen}
          location={location}
          listings={listings || []}
          onMapMoveEnd={onMapMoveEnd}
          onCloseAsModal={() => {
            onManageDisableScrolling('SearchPage.map', false);
          }}
          messages={intl.messages}
        />
      </div>
    </SearchModal>
  );

  return <div>{isSearchMapFilterOpen ? modal : null}</div>;
}

export default MapListingsModal;
